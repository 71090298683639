<template>
  <div>
    <div id="global-market-page" class="flex-column">
      <div class="flex-row">
        <div v-if="vehicleDetails.vehicle" class="flex-column">
          <h1 class="h1-title">
            {{ vehicleDetails.vehicle.year }}, {{ vehicleDetails.vehicle.make }} {{ vehicleDetails.vehicle.model }}
          </h1>
          <h4 class="h4">{{ vehicleDetails.vehicle.title }}</h4>
          <h4 class="h4">
            {{ vehicleDetails.vehicle.series }}, {{ vehicleDetails.vehicle.fuel }} {{ vehicleDetails.vehicle.drive }},
            {{ vehicleDetails.vehicle.body_type }}
          </h4>
          <h5 class="h5">AutoGrab ID: {{ vehicleDetails.vehicle.id }}</h5>
          <div class="margin-top-auto" v-if="hasForm">
            <div class="flex-row">
              <p class="body-2-bold margin-right-025">Additions:</p>
              <p class="body-2">{{ specObjectsString() }}</p>
            </div>
            <div class="flex-row margin-top-05">
              <p class="body-2-bold margin-right-025">Highest Offer:</p>
              <p class="body-2">{{ getHighestValue() ? "$" + Number(getHighestValue()).toLocaleString() : "None" }}</p>
            </div>
            <div class="flex-row margin-top-05">
              <p class="body-2-bold margin-right-025">Final Offer:</p>
              <p class="body-2">
                {{
                  offerDetails.finalOffer ? "$" + Number(getTopValue(offerDetails.finalOffer)).toLocaleString() : "None"
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="margin-left-auto margin-right min-width-25p" v-if="autoGrabFetchedData">
          <autograb-box :hideButtons="true" :auto_grab_fetched_data="autoGrabFetchedData" />
          <div class="flex-row">
            <secondary-button
              class="margin-left-auto margin-top"
              title="Trade Price Calculator"
              v-on:click="showTradePriceCalculator = true"
            />
          </div>
        </div>
      </div>
      <div v-if="leads" class="flex-row margin-top">
        <h5 class="flex-row-start h5">Fetched {{ dateToDayMonthYearTimeReadableString(new Date()) }}</h5>
        <div class="margin-left-auto flex-row">
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">Listing Source:</label>
            <input class="text-field" type="text" v-model="filterHandler.listingFilter" placeholder="Listing Source" />
          </div>
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">Seller Type:</label>
            <select class="dropdown-field" v-model="filterHandler.sellerTypeFilter">
              <option value="">All</option>
              <option value="Dealer">Dealer</option>
              <option value="Private">Private</option>
            </select>
          </div>
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">Active:</label>
            <select class="dropdown-field" v-model="filterHandler.activeFilter">
              <option value="">All</option>
              <option value="1">Active</option>
              <option value="2">Delisted</option>
            </select>
          </div>
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">State:</label>
            <select class="dropdown-field" v-model="filterHandler.stateFilter">
              <option value="">All</option>
              <option value="SA">SA</option>
              <option value="NSW">NSW</option>
              <option value="VIC">VIC</option>
              <option value="QLD">QLD</option>
              <option value="WA">WA</option>
              <option value="NT">NT</option>
              <option value="TAS">TAS</option>
              <option value="ACT">ACT</option>
            </select>
          </div>
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">Age:</label>
            <select class="dropdown-field" v-model="filterHandler.daysFilter">
              <option value="30">30d</option>
              <option value="60">60d</option>
              <option value="90">90d</option>
              <option value="120">120d</option>
              <option value="180">180d</option>
              <option value="365">365d</option>
            </select>
          </div>
          <div class="flex-column margin-right">
            <label class="text-field-title margin-bottom-025">+/- 1 Year:</label>
            <select class="dropdown-field" v-model="filterHandler.includeAdjacedFilter">
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
        </div>
      </div>
      <p class="body-2">
        Leads: {{ leads.length }} total ({{ leads.filter(lead => !lead.removed_at).length }} active,
        {{ leads.filter(lead => lead.removed_at).length }} delisted)
      </p>
      <div v-if="!showNoLeadsMessage() && !loading" class="flex-row margin-top" style="position: relative">
        <div class="flex-row margin-top" style="width: 33%">
          <div
            v-if="!loading"
            class="shadow padding rounded-corners sticky"
            style="
              overflow: hidden;
              margin-right: auto;
              margin-left: auto;
              width: 95%;
              margin-bottom: auto;
              margin-top: 0px;
            "
          >
            <p class="body-2" style="margin-top: 0px">Average Listing Price: {{ getAverageValue() }}</p>
            <apexchart
              type="scatter"
              width="100%"
              height="300"
              :options="getLeadsGraph.options"
              :series="getLeadsGraph.series"
              @dataPointSelection="dataPointSelection"
              @dataPointMouseEnter="dataPointMouseEnter"
              @dataPointMouseLeave="dataPointMouseLeave"
            ></apexchart>
            <div>
              <label class="margin-right-025 margin-bottom-025 text-field-title">Set Comparison Listing Price:</label>
              <div>
                $<input
                  type="number"
                  name="comparison_listing_price"
                  id="comparison_listing_price"
                  class="text-field margin-left-025"
                  v-model="comparisonLead.price"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="results-table-container position-relative">
          <div ref="hidden" class="hidden-element"></div>
          <table ref="leadContainer" class="results-table scroll-margin-top">
            <tr class="header">
              <th style="width: 60px">Photo</th>
              <th>Year</th>
              <th>Release</th>
              <th>Listed At</th>
              <th class="pointer-cursor" v-on:click="setSortKey(0)">
                Age {{ filterHandler.sortBy == 0 ? (!filterHandler.reversed ? "▼" : "▲") : "" }}
              </th>
              <th>Seller Type</th>
              <th>Color</th>
              <th>State</th>
              <th class="pointer-cursor" v-on:click="setSortKey(1)">
                Listing Price {{ filterHandler.sortBy == 1 ? (!filterHandler.reversed ? "▼" : "▲") : "" }}
              </th>
              <th>Starting Price (Price Drops)</th>
              <th class="pointer-cursor" v-on:click="setSortKey(2)">
                Mileage (Kms) {{ filterHandler.sortBy == 2 ? (!filterHandler.reversed ? "▼" : "▲") : "" }}
              </th>
              <th>Tags</th>
              <th>Listing Sources</th>
            </tr>
            <tr
              class="data-row"
              :class="{
                delisted: lead.removed_at,
                hoveredLead: lead == hoveredLead,
                comparisonLead: lead == comparisonLead
              }"
              v-for="(lead, i) in sortedLeads()"
              v-bind:key="i"
              :ref="lead.listing_url"
            >
              <td style="width: 60px" class="position-relative">
                <img class="thumbnail" v-if="lead.cover_image_url" :src="lead.cover_image_url" alt="" srcset="" />
              </td>
              <td>{{ lead.year }}</td>
              <td>
                {{ mapMonthNumberToMonth(lead.release_month)
                }}{{ mapMonthNumberToMonth(lead.release_month) ? "," : "" }} {{ lead.release_year }}
              </td>
              <td>{{ dateToDayMonthYearReadableString(lead.listed_at) }}</td>
              <td>{{ ageInDays(lead.removed_at ? lead.removed_at : lead.listed_at) }}d</td>
              <td class="capitalize">{{ lead.seller_type }}</td>
              <td class="capitalize">{{ lead.color }}</td>
              <td>{{ lead.state }}</td>
              <td>${{ Number(lead.price).toLocaleString() }}</td>
              <td>{{ lead.price_drop_count ? "$" + lead.starting_price + " (" + lead.price_drop_count + ")" : "" }}</td>
              <td>{{ Number(lead.kms).toLocaleString() }}</td>
              <td class="capitalize">{{ mapTagArrayToString(lead.tag_ids) }}</td>
              <td class="capitalize">
                <a
                  v-for="listing in lead.listing_details"
                  v-bind:key="listing.source"
                  :href="listing.url"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  class="margin-right-025"
                  >{{ mapWebsiteToString(listing.source) }}</a
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div v-if="showNoLeadsMessage()" class="flex-column padding">
      <h3 class="h3-title flex-center">There were no leads found matching these conditions.</h3>
      <h3 class="h3-subtitle flex-center">Try expanding your filters to find more leads.</h3>
    </div>

    <div v-if="loading" class="flex-row">
      <img src="/assets/img/loader.svg" alt="" class="flex-center margin-top" />
    </div>

    <trade-price-calculator
      v-if="showTradePriceCalculator"
      :damages="vehicleDetails.bodyDamages"
      @cancel="showTradePriceCalculator = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { requestAutoGrabLiveMarketFromForm, requestAutoGrabVehicleDetailsFromForm } from "../../../api/Form.js";
import { requestAutoGrabLiveMarketFromAGFD, requestAutoGrabVehicleDetailsFromAGFD } from "../../../api/GlobalMarket.js";
import {
  dateToDayMonthYearTimeReadableString,
  dateToDayMonthYearReadableString,
  monthArray
} from "../../../helpers/utilityHelpers.js";
import { getTopValue } from "../../../helpers/offerHelpers.js";
import VueApexCharts from "vue3-apexcharts";

import AutograbBox from "../../../components/Views/v2/AutograbBox.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import TradePriceCalculator from "../../../components/Modals/v2/TradePriceCalculator.vue";

let tagDictionary = {
  extras: "Extras",
  trash: "Trash",
  mismatch: "Missing Details",
  hot: "Urgent",
  sick: "Sick",
  writeoff: "Writeoff",
  new: "New Car In Stock",
  new_to_order: "New Car To Order",
  demo: "Dealer Demo",
  carsales_good: "Good Price",
  damaged: "Damaged",
  carsales_great: "Great Price",
  carsales_low: "Low Price",
  cheap: "Cheap",
  price_drop: "Price Drop",
  unicorn: "Unicorn"
};

export default {
  name: "GlobalMarket",
  components: {
    AutograbBox,
    TradePriceCalculator,
    SecondaryButton,
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      loading: false,
      autoGrabFetchedData: null,
      leads: [],
      hoveredLead: null,
      showTradePriceCalculator: false,
      vehicleDetails: {
        vehicle: null,
        specObjects: [],
        bodyDamages: []
      },
      offerDetails: {
        finalOffer: null,
        highestOffer: null,
        highestAdjustedOffer: null
      },
      filterHandler: {
        sortBy: 1,
        reversed: false,
        sellerTypeFilter: "",
        activeFilter: "1",
        stateFilter: "",
        daysFilter: 365,
        includeAdjacedFilter: false,
        listingFilter: ""
      },
      comparisonLead: {
        listing_url: "myurl",
        year: this.vehicleDetails && this.vehicleDetails.vehicle ? this.vehicleDetails.vehicle.year : "",
        release_month: "",
        release_year: "",
        listed_at: new Date(),
        removed_at: null,
        seller_type: "Dealer",
        color: "",
        state: "",
        price: 0,
        kms: 0,
        tag_ids: "",
        listing_sources: []
      }
    };
  },
  methods: {
    fetchLiveMarket: function () {
      this.loading = true;
      if (this.$route.params.formId) {
        requestAutoGrabLiveMarketFromForm(
          this.$route.params.formId,
          this.filterHandler.daysFilter,
          this.filterHandler.includeAdjacedFilter
        )
          .then(response => {
            this.loading = false;
            this.autoGrabFetchedData = response.agfd;
            this.leads = response.leads;
            this.vehicleDetails.specObjects = response.spec_objects;
            this.vehicleDetails.bodyDamages = response.body_damages;
            this.offerDetails.finalOffer = response.final_offer;
            this.offerDetails.highestOffer = response.highest_offer;
            this.offerDetails.highestAdjustedOffer = response.highest_offer_adjusted;
          })
          .catch(error => {
            this.loading = false;
            this.addError(error);
          });
      } else if (this.$route.params.agfdId) {
        requestAutoGrabLiveMarketFromAGFD(
          this.$route.params.agfdId,
          this.filterHandler.daysFilter,
          this.filterHandler.includeAdjacedFilter
        )
          .then(response => {
            this.loading = false;
            this.autoGrabFetchedData = response.agfd;
            this.leads = response.leads;
          })
          .catch(error => {
            this.loading = false;
            this.addError(error);
          });
      }
    },
    fetchVehicleDetails: function () {
      if (this.$route.params.formId) {
        requestAutoGrabVehicleDetailsFromForm(this.$route.params.formId)
          .then(response => {
            this.vehicleDetails.vehicle = response;
          })
          .catch(error => {
            this.addError(error);
          });
      } else if (this.$route.params.agfdId) {
        requestAutoGrabVehicleDetailsFromAGFD(this.$route.params.agfdId)
          .then(response => {
            this.vehicleDetails.vehicle = response;
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    sortedLeads: function () {
      if (this.leads.count <= 0) {
        return [];
      }

      var toReturn = [...this.leads];
      if (this.filterHandler.sellerTypeFilter) {
        toReturn = toReturn.filter(lead => {
          if (lead.seller_type) {
            return lead.seller_type.toLowerCase() == this.filterHandler.sellerTypeFilter.toLowerCase();
          } else {
            return false;
          }
        });
      }
      if (this.filterHandler.activeFilter) {
        toReturn = toReturn.filter(lead => {
          if (this.filterHandler.activeFilter == 1) {
            return !lead.removed_at;
          } else if (this.filterHandler.activeFilter == 2) {
            return lead.removed_at;
          } else {
            return true;
          }
        });
      }
      if (this.filterHandler.stateFilter) {
        toReturn = toReturn.filter(lead => {
          if (lead.state) {
            return lead.state.toLowerCase() == this.filterHandler.stateFilter.toLowerCase();
          } else {
            return false;
          }
        });
      }

      if (this.filterHandler.listingFilter) {
        toReturn = toReturn.filter(lead => {
          return (
            lead.listing_details.filter(listing => {
              return listing.source.toLowerCase().includes(this.filterHandler.listingFilter.toLowerCase());
            }).length > 0
          );
        });
      }

      toReturn.push(this.comparisonLead);

      toReturn = toReturn.sort((lead1, lead2) => {
        var toReturn = 0;
        if (this.filterHandler.sortBy == 0) {
          toReturn = this.ageInDays(lead1.listed_at) - this.ageInDays(lead2.listed_at);
        } else if (this.filterHandler.sortBy == 1) {
          toReturn = lead1.price - lead2.price;
        } else {
          toReturn = lead1.kms - lead2.kms;
        }
        return toReturn * (this.filterHandler.reversed ? -1 : 1);
      });

      return toReturn;
    },
    showNoLeadsMessage() {
      return (
        !this.loading &&
        this.sortedLeads().filter(lead => {
          return lead != this.comparisonLead;
        }).length == 0
      );
    },
    setSortKey: function (sortKey) {
      if (this.filterHandler.sortBy == sortKey) {
        this.filterHandler.reversed = !this.filterHandler.reversed;
      } else {
        this.filterHandler.sortBy = sortKey;
      }
    },
    dateToDayMonthYearTimeReadableString: function (date) {
      return dateToDayMonthYearTimeReadableString(date);
    },
    dateToDayMonthYearReadableString: function (date) {
      return dateToDayMonthYearReadableString(date);
    },
    ageInDays: function (dateString) {
      var dateStringAsDate = new Date(dateString);
      if (dateStringAsDate) {
        var dateDiff = new Date() - dateStringAsDate;
        return Math.round(dateDiff / (1000 * 60 * 60 * 24));
      }
    },
    mapTagArrayToString: function (array) {
      let entries = Object.entries(array);
      if (entries.length > 0) {
        var toReturn = "";
        entries.forEach(([, value], i) => {
          if (tagDictionary[value]) {
            toReturn += tagDictionary[value];
          } else {
            toReturn += value;
          }

          if (i != entries.length - 1) {
            toReturn += ", ";
          }
        });
        return toReturn;
      } else {
        return "";
      }
    },
    mapWebsiteArrayToString: function (array) {
      let entries = Object.entries(array);
      if (entries.length > 0) {
        var toReturn = "";
        entries.forEach(([, value], i) => {
          toReturn += value.split(".")[0];
          if (i != entries.length - 1) {
            toReturn += ", ";
          }
        });
        return toReturn;
      } else {
        return "";
      }
    },
    mapWebsiteToString: function (website) {
      return website.split(".")[0];
    },
    mapMonthNumberToMonth(month) {
      return monthArray[month];
    },
    specObjectsString: function () {
      if (this.vehicleDetails.specObjects.length == 0) {
        return "None";
      }
      var toReturn = "";
      this.vehicleDetails.specObjects.forEach((specObject, i) => {
        toReturn += specObject.description;
        if (i != this.vehicleDetails.specObjects.length - 1) {
          toReturn += ", ";
        }
      });

      return toReturn;
    },
    getTopValue(offer) {
      return getTopValue(offer, true);
    },
    getHighestValue() {
      var highestOfferPrice = 0;
      var highestAdjustedOfferPrice = 0;
      if (this.offerDetails.highestOffer) {
        highestOfferPrice = getTopValue(this.offerDetails.highestOffer, true);
      }
      if (this.offerDetails.highestAdjustedOffer) {
        highestAdjustedOfferPrice = getTopValue(this.offerDetails.highestAdjustedOffer, true);
      }
      return highestOfferPrice > highestAdjustedOfferPrice ? highestOfferPrice : highestAdjustedOfferPrice;
    },
    getAverageValue() {
      if (this.loading) {
        return "Loading...";
      }
      let leads = this.sortedLeads().filter(lead => {
        return lead != this.comparisonLead;
      });
      if (leads.length <= 0) {
        return "No Leads";
      }
      return "$" + (leads.reduce((partialSum, lead) => partialSum + lead.price, 0) / leads.length).toFixed(0);
    },
    dataPointSelection: function () {
      if (this.hoveredLead && this.hoveredLead.listing_details) {
        if (Object.keys(this.hoveredLead.listing_details).length > 0)
          window.open(this.hoveredLead.listing_details[0].url, "_blank");
      }
    },
    dataPointMouseEnter: function (_event, _chartContext, config) {
      if (config.seriesIndex == 0) {
        this.hoveredLead = this.sortedLeads().filter(lead => !lead.removed_at && !(lead == this.comparisonLead))[
          config.dataPointIndex
        ];
      } else if (config.seriesIndex == 1) {
        this.hoveredLead = this.sortedLeads().filter(lead => lead.removed_at)[config.dataPointIndex];
      } else if (config.seriesIndex == 2) {
        this.hoveredLead = this.comparisonLead;
      }

      let index = this.sortedLeads().indexOf(this.hoveredLead);
      let app = this;
      this.$nextTick(() => {
        if (index > 10) {
          let target = app.$refs[app.hoveredLead.listing_url];
          target.scrollIntoView({ block: "center" });
        } else {
          let target = app.$refs["hidden"];
          target.scrollIntoView({ block: "start" });
        }
      });
    },
    dataPointMouseLeave: function () {
      this.hoveredLead = null;
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    this.fetchLiveMarket();
    this.fetchVehicleDetails();
  },
  computed: {
    hasForm: function () {
      return this.$route.params.formId;
    },
    getLeadsGraph() {
      var graphableActiveLeads = [];
      var graphableDelistedLeads = [];
      var graphableComparisonLeads = [];
      this.sortedLeads().forEach(lead => {
        if (lead == this.comparisonLead) {
          graphableComparisonLeads.push([lead.kms / 1000, lead.price]);
        } else {
          if (lead.removed_at) {
            graphableDelistedLeads.push([lead.kms / 1000, lead.price]);
          } else {
            graphableActiveLeads.push([lead.kms / 1000, lead.price]);
          }
        }
      });

      var series = [
        {
          name: "Active",
          data: graphableActiveLeads
        },
        {
          name: "Delisted",
          data: graphableDelistedLeads
        },
        {
          name: "Comparison",
          data: graphableComparisonLeads
        }
      ];

      return {
        series: series,
        options: {
          chart: {
            type: "scatter",
            zoom: {
              type: "xy"
            }
          },
          colors: ["#384de3", "#f6153a", "#338301"],
          dataLabels: {
            enabled: false
          },
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            }
          },
          xaxis: {
            title: { text: "Kms (1000 km)" },
            tickAmount: 10
          },
          yaxis: {
            title: { text: "Listing Price ($)" }
          }
        }
      };
    },
    ...mapGetters({
      forms: "formStore/getForms"
    })
  },
  watch: {
    "filterHandler.daysFilter": function () {
      this.fetchLiveMarket();
    },
    "filterHandler.includeAdjacedFilter": function () {
      this.fetchLiveMarket();
    },
    autoGrabFetchedData: function () {
      this.comparisonLead.kms = this.autoGrabFetchedData.vehicle_mileage;
    },
    "offerDetails.finalOffer": function () {
      this.comparisonLead.price = getTopValue(this.offerDetails.finalOffer);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#global-market-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.hoveredLead {
  background-color: $BLUE_COLOR !important;
}
.rounded-corners {
  border-radius: 8px;
}
.comparisonLead {
  background-color: $ACTIVE_EVENT_COLOR;
  color: white;
}

.delisted {
  background-color: rgba($color: $ERROR_RED_COLOR, $alpha: 0.3);
}

.sticky {
  position: sticky;
  top: 156px;
  background-color: white;
  z-index: 500;
}

.scroll-margin-top {
  scroll-padding-top: 256px;
}

.hidden-element {
  position: absolute;
  height: 156px;
  top: -156px;
}

.thumbnail {
  position: absolute;
  height: 31px;
  width: 31px;
  top: 4px;

  transition: 0.3s;
  object-fit: cover;

  &:hover {
    height: 160px;
    width: 160px;
    z-index: 999;
  }
}
</style>
